.icon-notify {
    mask: url("./../assets/img/icon-notify.svg") no-repeat 50% 50%;
  }
  
  .icon-moveFile {
    mask: url("./../assets/img/icon-moveFile.svg") no-repeat 50% 50%;
  }
  
  .icon-pencil {
    background-color: #9b247f;
    mask: url("./../assets/img/icon-pencil.svg") no-repeat 50% 50%;
  }
  
    .icon-blank_doc {
      width: 20px;
      height: 20px;
      background-color: #00b5e2;
      mask: url("./../assets/img/document_blank.svg") no-repeat 50% 50%;
    }
    
    .icon-image_doc {
      width: 20px;
      height: 20px;
      background-color: #00b5e2;
      mask: url("./../assets/img/document_image.svg") no-repeat 50% 50%;
    }
    
    .icon-action_download {
      width: 24px;
      height: 24px;
      background-color: #00b5e2;
      mask: url("./../assets/img/action_download.svg") no-repeat 50% 50%;
    }
    
    .icon-action_new-submittal {
      width: 24px;
      height: 24px;
      background-color: #9b247f;
      mask: url("./../assets/img/action_new-submittal.svg") no-repeat 50% 50%;
    }
  
  .icon-action_new-submittal {
    width: 24px;
    height: 24px;
    background-color: #9b247f;
    mask: url("./../assets/img/action_new-submittal.svg") no-repeat 50% 50%;
  }
  
  .icon-cus_green {
    background-color: #008768 !important;
  }

  .icon-purple {
    background-color: #9b247f;
  }

  .icon-action-basic_todo {
    width: 60px;
    height: 60px;
    background-color: #aaaaaa;
    mask: url("./../assets/img/icon-basic_todo.svg") no-repeat 50% 50%;
  }